import React, { useEffect, useRef, useState } from "react";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import Loader from "components/loader";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useHelpMyself } from "hooks/Forms/useHelpMyself";
import { useForm } from "react-hook-form";
import formCommonPostData from "utils/form-common-post-data";

const HelpMyself = () => {
  const { data } = useFetchLanguages();
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(false);
  const { status, mutateAsync } = useHelpMyself();
  const [targetWord, setTargetWord] = useState("");

  const captchaRef = useRef(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (token) {
      const postData1 = {
        source_language: data.source_language,
        target_language: data.target_language,
        source_word_count: data.source_word_count,
      };
      // console.log(postData);
      const postData = { ...postData1, ...formCommonPostData() }
      mutateAsync(postData).then((res) => {
        // console.log(res);
        if (res.success > 0) {
          setTargetWord(res.data);
        } else {
          alert("Wrong Input");
        }

        setOpen(true);
        reset();
      });
    } else {
      alert("Please verify captcha");
    }
  };

  const handleVerify = () => {
    setToken(true);
  };

  // console.log(targetWord);

  return (
    <div className="bg-midBlue py-12">
      <h1 className="text-white font-bold text-2xl py-4 text-center">
        Help Myself
      </h1>
      <div className="flex w-11/12 sm:w-5/12 justify-center mx-auto h-[37rem] sm:h-[26rem] bg-white rounded">
        <form
          className="m-auto flex flex-col w-11/12"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex sm:flex-row flex-col justify-between mx-auto w-full">
            <label htmlFor="" className="w-[20rem] pb-2 sm:px-5">
              Source Language
            </label>
            <select
              name="target_language"
              id=""
              className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
              {...register("target_language", { required: true })}
            >
              <option disabled selected>
                Translate from
              </option>
              {data
                ? data?.data?.map(({ source_language }) => (
                  <option className="py-2 px-4 pr-10 mt-4 text-black bg-white w-full rounded">
                    {source_language}
                  </option>
                ))
                : null}
            </select>
          </div>
          <div className="flex sm:flex-row flex-col justify-between mx-auto w-full">
            <label htmlFor="" className="w-[20rem] pb-2 sm:px-5">
              Target Language
            </label>
            <select
              name="source_language"
              id=""
              className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
              {...register("source_language", { required: true })}
            >
              <option disabled selected>
                Translate to
              </option>
              {data
                ? data?.data?.map(({ source_language }) => (
                  <option className="py-2 px-4 pr-10 mt-4 text-black bg-white w-full rounded">
                    {source_language}
                  </option>
                ))
                : null}
            </select>
          </div>
          <div className="flex sm:flex-row flex-col mx-auto justify-between w-full">
            <label htmlFor="" className="w-[20rem] pb-2 sm:px-5">
              Word count in source document
            </label>
            <input
              type="text"
              name="source_word_count"
              className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
              {...register("source_word_count", { required: true })}
            />
          </div>
          <div className="flex sm:flex-row flex-col mx-auto justify-end w-full">
            <div className="mr-[38px]">
              <HCaptcha
                sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
                onVerify={handleVerify}
                ref={captchaRef}
              />
            </div>
            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
            <button
              type="submit"
              className="bg-orange text-new max-w-max text-white py-2 px-10 mb-4 rounded-full  cursor-pointer font-opensans mt-6"
            >
              {status === "loading" ? <Loader /> : "SUBMIT"}
            </button>
          </div>
          <div className="flex sm:flex-row flex-col mx-auto justify-between w-full pt-3">
            <label htmlFor="" className="w-[20rem] pb-2 sm:px-5">
              Estimated word count in target document
            </label>
            <input
              disabled
              type="text"
              value={targetWord}
              className="border-borderGray bg-white font-opensans border rounded lg:w-11/12 w-full md:w-[unset] pl-3  h-[34px] text-xs mb-6"
            />
          </div>
        </form>
      </div>
    </div>
  );
};;

export default HelpMyself;
