import Layout from "components/layout";
import HelpMyself from "components/wordCountRatio/HelpMyself";
import React from "react";
import Seo from "components/seo";

const index = () => {
  return (
    <Layout>
      <Seo
        title="Help Myself - Word Count Ratio Tool"
        slug="/tools/word-count-ratio/help-myself"
      />
      <HelpMyself />
    </Layout>
  );
};

export default index;
